.body {
    background-image: url('https://pink-moment.s3.us-west-1.amazonaws.com/pinkMoment-cropped.jpeg');
    background-size: cover;
    background-position: center;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    padding-top: 35vh;
    padding-bottom: 10px;
}

.clock {
    /* position: absolute;
            top: 50%;
            left: 50%; */
    /* transform: translate(-50%, -50%); */
    color: white;
    font-size: 36px;
}

.time-left-wrapper {
    /* position: absolute;
            top: 70%;
            left: 50%; */
    /* transform: translate(-50%, -50%); */
    color: white;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
    max-width: 700px;
    width: 90%;
}

.share-button {
    background-color: #4681f4;
    border-radius: 6px;
    border: none;
    color: #fff;
    padding: 15px 20px;
    /* position: absolute;
            top: 90%;
            left: 50%; */
    font-size: 24px;
    margin-bottom: 100px;
    margin-top: 20px;
}